import Link from "next/link";
import Image from "next/image";
import styled from "styled-components";
import IconFacebook from "lib/assets/facebook2.svg";
import IconLinkedin from "lib/assets/linkedin.svg";
import IconX from "lib/assets/x.png";
import IconYoutube from "lib/assets/youtube.png";
import IconInstagram from "lib/assets/instagram.svg";
import IconMedium from "lib/assets/medium.svg";
import useIsResMobile from "lib/hooks/useIsResMobile";
import { getAirbridgeTrackerLink } from "lib/airbridge";

const Footer = () => {
  const isResMobile = useIsResMobile();

  return (
    <Container
      css={`
        padding: 28px ${isResMobile ? 20 : 50}px ${isResMobile ? 75 : 30}px;
        line-height: ${isResMobile ? `18px` : `22px`};
        font-size: ${isResMobile ? 12 : 14}px;
        flex-grow: 0;
      `}
    >
      <Wrapper>
        <div
          css={`
            margin-bottom: 50px;
            max-width: ${isResMobile ? `100%` : `700px`};
            display: flex;
            flex-basis: ${isResMobile ? `100%` : `80%`};
            justify-content: space-between;
          `}
        >
          <LinkList>
            <ListTitle>
              <Link passHref href="/case-tracker">
                <FooterLink>Case Tracker</FooterLink>
              </Link>
            </ListTitle>
            <ListItem>
              <a
                href={getAirbridgeTrackerLink(
                  isResMobile,
                  "Footer",
                  undefined,
                  "https://apps.apple.com/us/app/my-uscis-case-status-tracker/id1435063223",
                )}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noreferrer"
                id="Footer_CaseTracker_iOSAppDownload"
                css={`
                  :hover {
                    text-decoration: underline;
                  }
                `}
              >
                iOS
              </a>
            </ListItem>
            <ListItem>
              <a
                href={getAirbridgeTrackerLink(
                  isResMobile,
                  "Footer",
                  undefined,
                  "https://play.google.com/store/apps/details?id=com.lawfully.lawfully_ai_tracker&hl=en_US&gl=US&pli=1",
                )}
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                rel="noreferrer"
                id="Footer_CaseTracker_AndroidAppDownload"
                css={`
                  :hover {
                    text-decoration: underline;
                  }
                `}
              >
                Android
              </a>
            </ListItem>
          </LinkList>
          <LinkList>
            <ListTitle>
              <Link passHref href="/resources">
                <FooterLink>Immigration 101</FooterLink>
              </Link>
            </ListTitle>
            <ListItem>
              <Link passHref href="/resources">
                <FooterLink>Immigration Q&A</FooterLink>
              </Link>
            </ListItem>
            <ListItem>
              <Link passHref href="/resources">
                <FooterLink>Marriage Green Card</FooterLink>
              </Link>
            </ListItem>
            <ListItem>
              <Link passHref href="/resources">
                <FooterLink>Citizenship</FooterLink>
              </Link>
            </ListItem>
          </LinkList>
          <LinkList>
            <ListTitle>
              <Link passHref href="/lawyers/states/all">
                <FooterLink>Find a Lawyer</FooterLink>
              </Link>
            </ListTitle>
            <ListTitle>
              <Link passHref href="/mock-interview">
                <FooterLink>Mock Interview</FooterLink>
              </Link>
            </ListTitle>
            <ListTitle>
              <Link passHref href="/community/categories/all">
                <FooterLink>Community</FooterLink>
              </Link>
            </ListTitle>
            {isResMobile && (
              <>
                <ListTitle>
                  <Link passHref href="/about#about">
                    <FooterLink>Company</FooterLink>
                  </Link>
                </ListTitle>
                <ListTitle>
                  <Link passHref href="/about#careers">
                    <FooterLink>Careers</FooterLink>
                  </Link>
                </ListTitle>
                <ListTitle>
                  <Link passHref href="/for-lawyers">
                    <FooterLink>For Lawyers</FooterLink>
                  </Link>
                </ListTitle>
              </>
            )}
          </LinkList>
          {!isResMobile ? (
            <LinkList>
              <ListTitle>
                <Link passHref href="/about#about">
                  <FooterLink>Company</FooterLink>
                </Link>
              </ListTitle>
              <ListTitle>
                <Link passHref href="/about#careers">
                  <FooterLink>Careers</FooterLink>
                </Link>
              </ListTitle>
              <ListTitle>
                <Link passHref href="/for-lawyers">
                  <FooterLink>For Lawyers</FooterLink>
                </Link>
              </ListTitle>
            </LinkList>
          ) : null}
        </div>

        <div
          css={`
            margin-top: ${isResMobile ? 10 : 0}px;
            margin-bottom: ${isResMobile ? 0 : 10}px;
            flex-basis: ${isResMobile ? `55px` : `auto`};
          `}
        >
          <LinkList
            css={`
              display: ${isResMobile ? `flex` : `block`};
            `}
          >
            <ListItem>
              <SocialLink
                href="https://x.com/lawfullytweet"
                target="_blank"
                rel="noreferrer"
                title="X"
                id="Footer_Social_Twitter"
              >
                <div
                  css={`
                    margin-right: ${isResMobile ? 12 : 4}px;
                    width: ${isResMobile ? "28px" : "auto"};
                    display: flex;
                    overflow: hidden;
                  `}
                >
                  <Image
                    src={IconX}
                    width={isResMobile ? 28 : 22}
                    height={isResMobile ? 28 : 22}
                  />
                </div>
                {!isResMobile && `X (Twitter)`}
              </SocialLink>
            </ListItem>
            <ListItem>
              <SocialLink
                href="https://www.facebook.com/lawfullytracker/"
                target="_blank"
                rel="noreferrer"
                title="Facebook"
                id="Footer_Social_Facebook"
              >
                <IconFacebook
                  width={isResMobile ? 28 : 22}
                  height={isResMobile ? 28 : 22}
                  viewBox="0 0 22 22"
                  style={{ marginRight: isResMobile ? 12 : 4 }}
                  color="white"
                />
                {!isResMobile && `facebook`}
              </SocialLink>
            </ListItem>
            <ListItem>
              <SocialLink
                href="https://www.linkedin.com/company/lawfully"
                target="_blank"
                rel="noreferrer"
                title="Linkedin"
                id="Footer_Social_LinkedIn"
              >
                <IconLinkedin
                  width={isResMobile ? 28 : 22}
                  height={isResMobile ? 28 : 22}
                  viewBox="0 0 22 22"
                  style={{ marginRight: isResMobile ? 12 : 4 }}
                  color="white"
                />
                {!isResMobile && `LinkedIn`}
              </SocialLink>
            </ListItem>
            <ListItem>
              <SocialLink
                href="https://www.youtube.com/channel/UC7KQxY3u2Q2ESTMe7cePfpg"
                target="_blank"
                rel="noreferrer"
                title="Youtube"
                id="Footer_Social_Youtube"
              >
                <div
                  css={`
                    margin-right: ${isResMobile ? 12 : 4}px;
                    width: ${isResMobile ? "28px" : "auto"};
                    display: flex;
                  `}
                >
                  <Image
                    src={IconYoutube}
                    width={isResMobile ? 28 : 22}
                    height={isResMobile ? 28 : 22}
                  />
                </div>
                {!isResMobile && `YouTube`}
              </SocialLink>
            </ListItem>
            <ListItem>
              <SocialLink
                href="https://www.instagram.com/lawfully_official/"
                target="_blank"
                rel="noreferrer"
                title="Instagram"
                id="Footer_Social_Instagram"
              >
                <IconInstagram
                  width={isResMobile ? 28 : 22}
                  height={isResMobile ? 28 : 22}
                  viewBox="0 0 22 22"
                  style={{ marginRight: isResMobile ? 12 : 4 }}
                  color="white"
                />
                {!isResMobile && `Instagram`}
              </SocialLink>
            </ListItem>
            <ListItem>
              <SocialLink
                href="https://lawfully-official.medium.com/"
                target="_blank"
                rel="noreferrer"
                title="Medium"
                id="Footer_Social_Medium"
              >
                <IconMedium
                  width={isResMobile ? 28 : 22}
                  height={isResMobile ? 28 : 22}
                  viewBox="0 0 22 22"
                  style={{ marginRight: isResMobile ? 12 : 4 }}
                  color="white"
                />
                {!isResMobile && `Medium`}
              </SocialLink>
            </ListItem>
          </LinkList>
        </div>

        <div
          css={`
            display: ${isResMobile ? `block` : `flex`};
            flex-basis: ${isResMobile ? `70%` : `100%`};
          `}
        >
          <Copyright>© {new Date().getFullYear()} Lawfully Inc.</Copyright>
          <Terms
            css={`
              margin-top: ${isResMobile ? 15 : 0}px;
            `}
          >
            <Link passHref href="/privacy-policy">
              <FooterLink>Privacy Policy</FooterLink>
            </Link>
            <span
              css={`
                padding: 0 10px 0;
              `}
            >
              |
            </span>
            <Link passHref href="/terms-of-service">
              <FooterLink>Terms of Service</FooterLink>
            </Link>
            <span
              css={`
                padding: 0 10px 0;
              `}
            >
              |
            </span>
            <Link passHref href="/limited-scope-representation-agreement">
              <FooterLink>Limited Scope Representation Agreement</FooterLink>
            </Link>
          </Terms>
        </div>
      </Wrapper>
    </Container>
  );
};

export default Footer;

const Container = styled.div`
  background: black;
  color: white;
  a {
    color: white;
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 894px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const LinkList = styled.ul`
  flex-basis: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  margin-bottom: 20px;
  color: white;
  :last-of-type {
    margin-bottom: 0;
  }
`;

const ListTitle = styled(ListItem)`
  font-weight: 700;
`;

const Terms = styled.div`
  color: #888;
  a,
  a:hover {
    color: #888;
  }
`;

const Copyright = styled(Terms)`
  margin-right: 30px;
`;

const FooterLink = styled.a`
  :hover {
    text-decoration: underline;
  }
`;

const SocialLink = styled.a`
  display: flex;
  opacity: 0.85;
  transition: opacity 120ms ease-in-out;
  :hover {
    opacity: 1;
  }
`;
